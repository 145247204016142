import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Clipper } from '../models/video';
import { SnackbarService } from '../components/snackbar/snackbar.service';
import { DataService } from './data.service';
import { ClipData } from '../models/clip';
import { MixpanelService } from './mixpanel.service';

@Injectable({ providedIn: 'root' }) export class VideoService {

  videoType: string | undefined;
  intervalProgress: any;
  videoOnload = false;
  pending = 5;

  downloading = {
    loading: false as boolean,
    value: 0 as number,
    message: '' as string
  }

  constructor(
    private translate: TranslateService,
    private http: HttpClient,
    private dataService: DataService,
    private snackBar: SnackbarService,
    private mixpanelService: MixpanelService
  ) { }

  setVideoOptions(url: string, extension: string) {
    switch (extension) {
      case 'mp4': this.videoType = 'video/mp4'; break;
      case 'm3u8': this.videoType = 'application/x-mpegURL'; break;
    }
    return {
      autoplay: false,
      controls: true,
      sources: [{ src: url, type: this.videoType }],
      bigPlayButton: false,
      controlBar: {
        pictureInPictureToggle: false,
        volumePanel: { inline: false },
      }
    }
  }

  download(url: string) {
    this.mixpanelService.track('Landing Download', { url: url });
    this.downloading.loading = true
    var req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.responseType = "blob";
    req.onload = () => {
      var blob = req.response;
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      if (this.dataService.clip.name) {
        const title = this.dataService.clip.name.replace(".", "-");
        const extension = url.split('.').pop();
        link.download = title + '.' + extension;
      } else {
        link.download = url.split('/').pop() as string;
      }
      link.click();
      this.downloading.loading = false;
      this.snackBar.success(this.translate.instant('videoConvert.downloaded'));
    };
    req.onreadystatechange = () => {
      if (req.readyState === 4 && req.status !== 200)
        return this.setError(this.translate.instant('downloadFailed'));
    };
    req.send();
  }

  convertToMp4() {
    this.mixpanelService.track('Landing Convert to mp4', { clip: this.dataService.clip });
    this.downloading.loading = true;
    let url = "https://clipper.seenka.com/clipper/";
    let query = {
      media_id: this.dataService.clip.media_id || this.dataService.clip.media.id,
      start_time: this.dataService.clip.airing_time.replace('Z', ''),
      end_time: this.dataService.clip.end_time.replace('Z', '')
    } as ClipData
    this.http.post(url, query).subscribe({
      next: (res) => {
        let clipper = res as Clipper;
        if (clipper.status_full !== 'done' && clipper.status_full !== 'incomplete') {
          this.intervalProgress = setInterval(() => this.getClipStatus(clipper.status_url), 1000);
        } else {
          this.setBar(clipper);
          this.download(clipper.url);
        }
      },
      error: () => {
        return setTimeout(() => this.convertToMp4(), 1000);
      }
    });
  }

  getClipStatus(url: string) {
    this.http.get(url).subscribe((clippStatus: any) => {
      let clipStatus: Clipper = clippStatus as any;
      this.setBar(clipStatus);
      if (clippStatus.status_full === 'done' || clippStatus.status_full === 'incomplete') {
        clearInterval(this.intervalProgress);
        this.download(clippStatus.url);
      }
    });
  }

  setBar(data: Clipper) {
    if (data.status_full === 'pending') {
      this.pending--;
      if (this.pending === 0) {
        clearInterval(this.intervalProgress);
        this.pending = 5;
        return this.convertToMp4();
      }
    }

    let mess = this.downloading.message
    switch (data.status_full) {
      case "pending":
        this.downloading.value = 10;
        this.downloading.message = this.translate.instant('videoConvert.buffering');
        break;
      case "downloading_sources":
        this.downloading.value = 20;
        this.downloading.message = this.translate.instant('videoConvert.recognizing');
        break;
      case "cutting_first_source":
        this.downloading.message = this.translate.instant('videoConvert.selecting');
        this.downloading.value = 30;
        break;
      case "concatenating":
        this.downloading.message = this.translate.instant('videoConvert.concatenating');
        this.downloading.value = 40;
        break;
      case "uploading":
        this.downloading.message = this.translate.instant('videoConvert.compiling');
        this.downloading.value = 50;
        break;
      case "done":
        this.downloading.value = 60;
        setTimeout(() => this.downloading.value = 70, 2000);
        setTimeout(() => this.downloading.value = 80, 4000);
        setTimeout(() => this.downloading.value = 90, 6000);
        this.downloading.message = this.translate.instant('videoConvert.downloading');
        break;
      case "incomplete":
        this.downloading.value = 60;
        setTimeout(() => this.downloading.value = 70, 2000);
        setTimeout(() => this.downloading.value = 80, 4000);
        setTimeout(() => this.downloading.value = 90, 6000);
        this.downloading.message = this.translate.instant('videoConvert.downloading');
        break;
      default: console.log(data.status_full);
    }
    if (this.downloading.message !== mess) this.snackBar.advice(this.downloading.message);
  }

  setError(err: any) {
    this.mixpanelService.track('Landing Download Error', { error: err });
    this.snackBar.error(err, this.dataService.params);
    this.downloading.loading = false;
  }

  ngOnDestroy() {
    clearInterval(this.intervalProgress);
  }

}
