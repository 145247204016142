import { Component } from '@angular/core';
import { Clip } from 'src/app/models/clip';
import { DataService } from 'src/app/services/data.service';
import { VideoService } from 'src/app/services/video.service';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent {

  constructor(
    public dataService: DataService,
    public videoService: VideoService
  ) { }

  setVideoOptions(asset: Clip) {
    return {
      autoplay: false,
      controls: true,
      sources: [{ src: asset.clip_url, type: 'video/mp4' }]  
    }
  }

}
