import { MatSnackBar } from '@angular/material/snack-bar';
import { MixpanelService } from '../../services/mixpanel.service';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' }) export class SnackbarService {

  constructor(
    private snackBar: MatSnackBar,
    private mixpanelService: MixpanelService
  ){}

  error(mes: string, params: any){
    params = {...params, endpoint: 'Landing', mensaje: mes }
    if(params.pk) params.email = atob(params.pk)
    this.mixpanelService.track('Service Error', params);
    this.snackBar.open(mes, "", {
      duration: 5000,
      verticalPosition: 'bottom',
      panelClass: ['common-snackbar', 'error-snackbar'],
     })
  }

  success(mes: string){
    this.snackBar.open(mes, "", {
      duration: 5000,
      verticalPosition: 'bottom',
      horizontalPosition: 'right',
      panelClass: ['common-snackbar', 'success-snackbar'],
     })
  }

  advice(mes: string){
    this.snackBar.open(mes, "", {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'left',
      panelClass: ['common-snackbar', 'advice-snackbar'],
     })
  }

  action(mes: string){
    this.snackBar.open(mes, "OK", {
      duration: 100000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['common-snackbar', 'action-snackbar'],
     })
  }

  close(){
    this.snackBar.dismiss();
  }

}
