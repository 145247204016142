import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from "src/environments/environment";
import { SnackbarService } from '../components/snackbar/snackbar.service';
import { Clip, Entity } from '../models/clip';
import { QueryParams } from '../models/queryParams';
import { LanguageService } from './language.service';
import { MixpanelService } from './mixpanel.service';
import { Subject, zip } from 'rxjs';
import { AiringsService } from './airings.service';
import * as moment from 'moment';
import { DomSanitizer, Title } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' }) export class DataService {

  clip = {} as Clip;
  clip$: Subject<any> = new Subject();

  carousel = [] as Clip[];

  params = {} as QueryParams;
  loader = 0;
  error: any = null;
  assetCorrupted = false;
  shortUrl = window.location.href;

  constructor(
    private mixpanelService: MixpanelService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private snackBar: SnackbarService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private airingsService: AiringsService,
    private titleService: Title,
    private sanitizer: DomSanitizer
  ) { }

  getData() {
    this.setInitParams();

    if (this.params.redirectTo) {
      this.mixpanelService.setParams(this.params);
      window.location.href = decodeURIComponent(this.params.redirectTo);
      return;
    }

    this.http.get(environment.gateway + 'airings/' + this.params.type + '/' + this.params.id).subscribe({
      next: (res: any) => {
        if (this.clip.clip_url === undefined) {
          this.dataReceived(res);
          this.getCarousel();
        }
      },
      error: (err) => this.setError(err)
    });

    if (this.params.type === 'asset') this.getAsset();
    this.getShortLink();
    this.languageService.currentLang = this.translateService.currentLang;
  };

  dataReceived(res: any) {
    this.titleService.setTitle(res.name);

    let formats = ["Nota", "Tweet", "Instagram Post", "Facebook Post", "Youtube Post", 'TikTok Post']
    if (formats.includes(res.format) && res.destination_url) window.location.href = res.destination_url;
    else {
      this.clip = res as Clip;
      if (!this.clip.material_type) this.setMaterialType();
      if (this.clip.material_type === "Image") this.clip.clip_url = this.clip.thumb_url;
      if(res.material_type === "Embed") {
        res.clip_url = this.sanitizeVideo(res.clip_url);
      }
      this.clip$.next(this.clip);
      this.getWsIcon();
      this.snackBar.close();
      this.loader = 0;
    }
  }

  private sanitizeVideo(url: string) {
    if (!url) return;
    const videoIdMatch = url.match(/[?&]v=([^&]+)/);
    if (videoIdMatch && videoIdMatch[1]) {
       let url = 'https://www.youtube.com/embed/' + videoIdMatch[1];
       return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
    this.mixpanelService.track("Landing: Url inválida para Youtube", url);
    return "";
  }

  setMaterialType() {
    const extensions = {
      Video: ['mp4', 'm3u8'],
      Audio: ['mp3'],
      Image: ['JPG', 'PNG']
    } as any;

    this.clip.material_type = Object.keys(extensions)
      .find(key => extensions[key]
        .find((fk: string) => this.clip.clip_url?.includes(fk))) || 'Text';
  }

  setInitParams() {
    this.loader++;
    this.params = this.route.snapshot.queryParams as QueryParams;
    this.languageService.setTranslate(this.params.lang);
    this.mixpanelService.init(this.params.email);
    setTimeout(() => {
      if (this.loader > 0) this.snackBar.advice(this.translateService.instant('loadingSlow'));
    }, 5000);
  }

  setError(err: any) {
    if (this.loader > 0) this.loader--;
    if (this.loader === 0 && this.clip.clip_url === undefined) {
      this.mixpanelService.track('Landing Error', err);
      switch (err.status) {
        case 403: this.error = 'not_found'; break;
        case 404: this.error = 'bad_request'; break;
        default: this.error = 'error';
      }
    }
  }

  getWsIcon() {
    let wsId = this.params.new_workspace_id || this.params.workspace_id;
    this.http.get(environment.gateway + 'workspace/logo/' + wsId).subscribe({
      next: (res: any) => {
        this.clip.workspace_icon = res.logo_url;
      },
      error: (err) => this.mixpanelService.track('Landing WorkspaceIcon Error', err)
    });
  }

  getShortLink() {
    let url = window.location.href;
    this.http.post(environment.gateway + 'dataset/shorten-link/', { url })
      .subscribe((res: any) => this.shortUrl = res.shortURL);
  }

  attempts = 0;

  getAsset(startTime = this.params.start_time, endTime = this.params.end_time) {
    this.loader++;
    if (!startTime || !endTime) this.tryDateRanges();
    else this.requestData(startTime, endTime);
  }

  tryDateRanges() {
    const dateRanges = Array.from({ length: 5 }, (_, i) => ({
      start: moment().subtract(i + 1, 'year').format('YYYY-MM-DD'),
      end: moment().subtract(i, 'year').format('YYYY-MM-DD')
    }));
    if (this.attempts < dateRanges.length) {
      const { start, end } = dateRanges[this.attempts++];
      this.requestData(start, end);
    } else this.setError({ status: 403 });
  }

  requestData(startTime: string, endTime: string) {
    if (this.clip.clip_url) return;
    let queryGeneral = this.airingsService.getGeneralData(this.params.id, startTime, endTime) as any;
    this.http.post<any[]>(environment.gateway + 'airings/', queryGeneral).subscribe({
      next: (res) => {
        if (res.length > 0) this.postGetAsset(startTime, endTime, res[0]);
        else this.tryDateRanges();
      },
      error: (err) => this.setError(err)
    });
  }

  postGetAsset(startTime: string, endTime: string, res: any) {
    if (res.keywords) res.keywords = res.keywords.split('|').map((keyword: any) => keyword.trim());
    this.getAuthor(startTime, endTime);
    if (this.clip.clip_url) return;
    this.dataReceived(res);
    this.getStartTime(startTime, endTime);
    this.getEntities(startTime, endTime);
  }

  getStartTime(startTime: string, endTime: string) {
    let queryStartTime = this.airingsService.getStartTime(this.params.id, startTime, endTime) as any;
    queryStartTime.order = "start_time";
    queryStartTime.asc = "true";
    this.http.post(environment.gateway + 'airings/', queryStartTime).subscribe((res: any) => {
      this.clip.start_time = res[0].start_time;
    });
  }

  getEntities(startTime: string, endTime: string) {
    let queryEntities = this.airingsService.getEntities(this.params.id, startTime, endTime);
    queryEntities.size = 100;
    this.http.post(environment.gateway + 'airings/', queryEntities).subscribe((res: any) => {
      this.clip.entities = res.sort((a: Entity, b: Entity) => a.name.localeCompare(b.name));
      this.getCarousel();
    });
  }

  getAuthor(startTime: string, endTime: string) {
    let queryAuthor = this.airingsService.getAuthor(this.params.id, startTime, endTime);
    this.http.post(environment.gateway + 'airings/', queryAuthor).subscribe((res: any) => {
      this.clip.author = res[0].name;
    });
  }

  getCarousel(quitFormat = false) {
    let queryCarousel = this.airingsService.getCarousel(this.params.id, this.clip.format);
    let entities = this.clip.entities.map((e: Entity) => e.name).join(',');
    let sectors = this.clip.entities.map((e: Entity) => e.sector).join(',');
    let industries = this.clip.entities.map((e: Entity) => e.industry).join(',');

    if (quitFormat) queryCarousel['format-filter_names'] = "";

    const observables = [];

    if (entities?.length) {
      observables.push(this.http.post<any[]>(environment.gateway + 'airings/', {
        ...queryCarousel,
        "entity-filter_names": entities,
      }));
    }

    if (sectors?.length) {
      observables.push(this.http.post<any[]>(environment.gateway + 'airings/', {
        ...queryCarousel,
        "entity_sector-filter_names": sectors,
        "entity-exclude_names": entities,
      }));
    }

    if (industries?.length) {
      observables.push(this.http.post<any[]>(environment.gateway + 'airings/', {
        ...queryCarousel,
        "entity_industry-filter_names": industries,
        "entity-exclude_names": entities,
        "sector-exclude_names": sectors,
      }));
    }

    zip(...observables).subscribe({
      next: (res) => {
        let assets = res
          .flat() // Combina los resultados si son arreglos
          .filter((item, index, self) => index === self.findIndex((t) => t.id === item.id))          // Elimina duplicados por id
          .sort((a, b) => {
            if (a.format === this.clip.format && b.format !== this.clip.format) return -1;
            if (a.format !== this.clip.format && b.format === this.clip.format) return 1;
            return b.impact - a.impact;
          })

        if (assets.length < 5 && !quitFormat) {
          this.carousel = assets;
          this.getCarousel(true);
        }
        else {
          assets.forEach((c: Clip) => {
            if (!this.carousel.map(car => car.id).includes(c.id) && this.carousel.length < 5)
              this.carousel.push(c);
          });
        }
        this.carousel = this.carousel.slice(0, 5);
      },
      error: (err) => this.mixpanelService.track('Landing Carousel Error', err),
    });
  }

}