import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' }) export class AiringsService {

  getGeneralData(assetId: string, startDate: string, endDate: string) {
    return {
      "structure": {
        "asset": { "mode": "cross" },
        "material_type": { "mode": "cross" },
        "tonality": { "mode": "cross" },
        "data_type": { "mode": "cross" },
        "keyword": { "mode": "inline" },
        "format": { "mode": "cross" },
      },
      "columns": {
        "id": "asset_id",
        "name": "asset_name",
        "thumb_url": "asset_thumb",
        "clip_url": "asset_clip",
        "amount": "frequency",
        "format": "format_name",
        "material_type": "material_type_name",
        "destination_url": "asset_destination",
        "asset_description": "asset_description",
        "tonality": "tonality_name",
        "impact": "impact",
        "valuation": "valuation",
        "keywords": "keyword_name"
      },
      "totals_impact": "on",
      "totals_valuation": "on",
      "totals_frequency": "on",
      ...this.commonParams(assetId, startDate, endDate)
    }
  }

  getStartTime(assetId: string, startDate: string, endDate: string) {
    return {
      "structure": {
        "asset": { "mode": "cross" },
        "airing": { "mode": "cross" }
      },
      "columns": {
        "start_time": "start_date_utc"
      },
      ...this.commonParams(assetId, startDate, endDate)
    }
  }

  getEntities(assetId: string, startDate: string, endDate: string) {
    return {
      "structure": {
        "entity": { "mode": "cross" },
        "entity_type": { "mode": "cross" },
        "entity_sector": { "mode": "cross" },
        "entity_industry": { "mode": "cross" },
        "entity_thumb": { "mode": "cross" },
      },
      "columns": {
        "name": "entity_name",
        "thumb_url": "entity_thumb",
        "sector": "entity_sector_name",
        "industry": "entity_industry_name",
        "entity_type": "entity_type_name"
      },
      ...this.commonParams(assetId, startDate, endDate)
    }
  }

  getAuthor(assetId: string, startDate: string, endDate: string) {
    return {
      "structure": {
        "asset": { "mode": "cross" },
        "author": { "mode": "cross" }
      },
      "columns": {
        "name": "author_name"
      },
      ...this.commonParams(assetId, startDate, endDate)
    }
  }

  commonParams(assetId: string, startDate: string, endDate: string) {
    return {
      "offset": 0,
      "start_date": startDate,
      "end_date": endDate,
      "asset-filter_ids": assetId,
      "size": 1,
      "timezone": "UTC",
      "submit": "Json"
    }
  }

  getCarousel(assetId: string, format: string) {
    return {
      "structure": {
        "asset": { "mode": "cross" },
        "entity": { "mode": "inline" },
        "material_type": { "mode": "cross" },
        "format": { "mode": "cross" },
      },
      "columns": {
        "id": "asset_id",
        "name": "asset_name",
        "thumb_url": "asset_thumb",
        "clip_url": "asset_clip",
        "entity": "entity_name",
        "landing": "asset_landing",
        "material_type": "material_type_name",
        "assetDestination": "asset_destination",
        "impact": "impact",
        "format": "format_name"
      },
      "format-filter_names": format,
      "offset": 0,
      "start_date": moment().subtract(1, 'month').format('YYYY-MM-DD'),
      "end_date": moment().format('YYYY-MM-DD'),
      "asset-exclude_ids": assetId,
      "size": 8,
      "timezone": "UTC",
      "submit": "Json",
      "totals_impact": "on",
      "totals_valuation": "on",
      "totals_frequency": "on",
    }
  }

}
