
<div [hidden]="!videoService.videoOnload">
  <video #target
    (loadedmetadata)="videoService.videoOnload = true"
    class="video-js vjs-big-play-centered vjs-16-9"
    [poster]="asset.thumb_url"
    [currentTime]="asset.offset || 0"
    playsinline
    data-setup='{ "playbackRates": [0.5, 0.75, 1, 1.5, 1.75, 2], "fluid": true }'
    preload="auto">
  </video>
</div>

<div *ngIf="!videoService.videoOnload" class="spinner" [style.height.px]="isMain ? 450 : 150">
  <mat-spinner [diameter]="40"></mat-spinner>
</div>

